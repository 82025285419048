<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="PATROL_TYPE_FST_CD"
            itemText="codeName"
            itemValue="code"
            label="상위구분"
            name="patrolFstCd"
            v-model="searchParam.patrolFstCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="PATROL_TYPE_SEC_CD"
            itemText="codeName"
            itemValue="code"
            label="하위구분"
            name="patrolSecCd"
            v-model="searchParam.patrolSecCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            label="추진부서"
            name="deptCd"
            v-model="searchParam.deptCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 추진기간 -->
          <c-datepicker
            :range="true"
            label="추진기간"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="searchParam.period"
          />
        </div>
      </template>
    </c-search-box>
    <!-- 순회점검 목록 -->
    <c-table
      ref="table"
      title="LBL00001606"
      :filtering="false"
      :columnSetting="false"
      :isFullScreen="false"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      rowKey="saiPatrolId"
      :selection="popupParam.type"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'patrolPop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
        {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'regDt',
            field: 'regDt',
            // 등록일
            label: '등록일',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolFstName',
            field: 'patrolFstName',
            // 상위구분
            label: '상위구분',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolSecName',
            field: 'patrolSecName',
            // 하위구분
            label: '하위구분',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolName',
            field: 'patrolName',
            // 제목
            label: '제목',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:200px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            // 부서
            label: '추진부서',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolDate',
            field: 'patrolDate',
            // 추진일정
            label: '추진일정',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
        height: '500px'
      },
      searchParam: {
        plantCd: null,
        vendorCd: null,
        useFlag: 'Y',
        startYmd: '',
        endYmd: '',
        period: [],
      },
      listUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.patrol.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.period) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = 'LBL00001601'; // 순회점검 상세
      this.popupOptions.param = row;
      this.popupOptions.target = () => import(`${'./patrolDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다. 
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
